.found-order-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: #f4f5f7a8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  min-height: 400px;

  .found-order-container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    background: white;
    -webkit-box-shadow: 3px 9px 16px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 3px 9px 16px 0px rgba(0, 0, 0, 0.7);
    font-family: AlmoniTzar;
    .title {
      display: flex;
      justify-content: center;
      font-size: 40px;
    }
    .order-details {
      margin: 30px 0;
      .row {
        display: flex;
        justify-content: center;
        justify-self: center;
        direction: rtl;
        .label {
          display: flex;
          font-size: 28px;
          color: #fc262f;
          width: 100px;
          text-align: left;
          padding: 0 10px;
          justify-content: left;
          line-height: 36px;
        }

        .value {
          display: flex;
          flex-direction: column;
          min-width: 190px;
          font-size: 28px;
          font-weight: 800;
          justify-content: right;
          padding: 0 10px;
          line-height: 36px;
        }
      }

      .row:nth-child(2n) {
        background: #fdfdfd;
      }
    }
  }
  .message-not-found-message-wrapper {
    display: flex;
    justify-content: center;
    font-size: 40px;
    margin: 30px;
  }
  .btn-holder {
    display: flex;
    justify-content: center;
    margin: 0 13px 20px;
    button {
      width: 160px;
      border-radius: 10px;
      font-size: 32px;
      padding: 0;
      line-height: 40px;
      margin: 3px 10px;
      margin: 0 7px;
    }
  }
}
