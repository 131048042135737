.login {
  .input-field {
    input {
      font-family: "AlmoniTzar";
      max-width: 300px;
      font-size: 42px;
      border-radius: 10px;
      text-align: center;
      height: 44px;
    }
    input::placeholder {
      font-size: 36px;
      text-align: center;
      line-height: 60px;
    }
  }
  button {
    width: 314px;
    border-radius: 10px;
    font-size: 32px;
    padding: 0;
    line-height: 40px;
    margin-top: 3px;
  }
}
