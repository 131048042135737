.input-wrapper {
  display: flex;
  justify-content: center;
}
.eye-icon {
  position: absolute;
  width: 26px;
  cursor: pointer;
  padding: 16px 20px;
}
