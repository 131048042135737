.sml-header-wrapper {
  font-family: Almoni;
  direction: rtl;
  display: flex;
  flex: 1;
  background: #333333;
  padding: 8px 55px;
  border-radius: 8px 8px 0 0;

  align-items: center;
  justify-content: space-between;
}

.sml-header-img-logo {
}

.sml-header-title {
  color: white;
  font-size: 32px;
  max-height: 40px;
  max-width: auto;
}
