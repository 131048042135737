.order-list-wrapper {
  display: block;
  justify-content: center;

  .order-list {
    margin: auto;
    overflow: auto;

    .table-container {
      table {
        table-layout: fixed;
        max-width: 98vw;

        thead {
          background-color: #ddd;

          input,
          select {
            font-size: 16px;
          }

          input:focus {
            border: 2px solid #f59b14;
          }
        }

        tr:nth-child(even) {
          background-color: #ddd;
        }

        th:nth-child(1) {
          input {
            width: 78px;
          }
        }

        th:nth-child(3) {
          input {
            width: 100px;
          }
        }
        th:nth-child(7) {
          input {
            width: 120px;
          }
        }

        tr:hover {
          background-color: #f59b14af;
          cursor: pointer;
        }

        td {
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          padding: 0 16px;
        }
      }
    }
  }

  .list-counter {
    padding-right: 2px;
    font-size: 16px;
    font-weight: bold;
    color: #8a8a8a;
    width: 160px;
  }
}

@media screen and (max-width: 1600px) {
  .order-list-wrapper .order-list .table-container table {
    max-width: 98vw;
    display: block;
    max-height: 60vh;
    overflow: scroll;
  }
}
