.noti-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: #f4f5f7a8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;

  .noti-modal-container {
    display: flex;
    flex-direction: column;
    background: white;
    -webkit-box-shadow: 3px 9px 16px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 3px 9px 16px 0px rgba(0, 0, 0, 0.7);
  }

  .end-email-content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 50px 0 30px 0;
    font-family: AlmoniTzar;
    font-weight: bold;
    font-size: 34px;
    text-align: center;
  }
  .btn-holder {
    display: flex;
    justify-content: center;

    .noti-modal-button {
      font-family: Almoni;
      margin: 4px 0 20px 0;
      padding: 10px 30px;
      font-size: 23px;
      text-align: center;
      background-color: #29a7df;
      width: 70px;
    }
  }
}
