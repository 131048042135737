.barcodeScanner {
  .barcode-wrapper {
    text-align: center;
    padding: 2px 8px;
    font-size: 22px;
    width: 36vw;
    min-height: 30vh;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    h1 {
      margin: 0px 0 12px 0;
    }
    .reader {
      background-color: #fff;
      border-radius: 15px;
    }
    .center-text {
      margin: 12px 0 0px 0;
    }

    .center-input {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      input {
        height: 26px;
        margin-bottom: 20px;
      }

      .btn-send {
        .button-white {
          padding: 4px 12px;
          margin-right: 8px;
          border-color: #1ebbd7;
          color: #1ebbd7;
        }
      }
    }

    .btn-close {
      margin-top: 16px;

      .button-white {
        padding: 8px 26px;
      }
    }

    @keyframes shake {
      0% {
        transform: translateX(0);
      }

      25% {
        transform: translateX(-5px);
      }

      50% {
        transform: translateX(5px);
      }

      75% {
        transform: translateX(-5px);
      }

      100% {
        transform: translateX(5px);
      }
    }

    .animated-div {
      margin-bottom: 8px;
      transition: transform 0.5s ease-in-out;
    }

    .shake {
      animation: shake 0.5s ease-in-out;
    }
  }

  @media (max-width: 850px) {
    .barcode-wrapper {
      width: 72vw;
      left: 12%;
    }
  }
}
