.noti-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: #f4f5f7a8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .noti-modal-container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    background: white;
    -webkit-box-shadow: 3px 9px 16px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 3px 9px 16px 0px rgba(0, 0, 0, 0.7);
  }

  .noti-modal-content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 50px;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
  }

  .btn-holder {
    display: flex;
    justify-content: center;

    button {
      margin: 0px 4px 14px 0;
    }
  }
}
