.order-by-code-wrapper {
  .input-field {
    input {
      font-family: "AlmoniTzar";
      max-width: 200px;
      font-size: 42px;
      border-radius: 10px;
      text-align: center;
      height: 44px;
    }
    input::placeholder {
      font-size: 36px;
      text-align: center;
      line-height: 60px;
    }
  }

  button {
    width: 218px;
    border-radius: 10px;
    font-size: 32px;
    padding: 0;
    line-height: 40px;
    margin: 3px 4px 3px 0;
  }
  .buttons-wrapper {
    margin-top: 100px;
  }
}
.right-bottom-box-wrapper {
  position: fixed;
  padding: 15px;
  bottom: 0;
  right: 0;
  width: 156px;
  button {
    width: 150px;
    height: 60px;
    margin: 3px;
  }
}
.left-bottom-box-wrapper {
  position: fixed;
  padding: 15px;
  bottom: 0;
  left: 0;
  width: 156px;
  button {
    width: 150px;
    height: 60px;
    margin: 3px;
  }
}
.btn-history {
  color: #29a7df;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0px 10px 0;
  text-decoration: underline;
}
