.table-container .pagination {
  max-width: 1000;
  margin: 0px 0 1rem 0;
  text-align: "center";
}

.table-container .pagination div {
  display: inline-block;
}

.export-button {
  color: #fff;
  background-color: rgb(39, 135, 245);
  box-shadow: 1px 3px 3px rgba(14, 56, 105, 0.2);
  border: rgb(14, 56, 105) solid 1px;
  border: 0;
  //font-weight: 600;
  margin: 0.2rem 0 0.5rem 0.4rem;
  padding: 0px 16px;
  font-family: Almoni;
  font-size: 18px;
  border-radius: 7px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #0b71b8;
  }

  &:active {
    margin-left: 1px 1px 0;
    box-shadow: 0px 2px 3px rgba(14, 56, 105, 0.4);
    border: 0;
  }

  .icon {
    margin-left: 8px;
    font-size: 16px;
  }
}
